import { Routes, Route, Link } from "react-router-dom";
import Home from "./Screens/Home";
import NotFound from "./Screens/NotFound";
import Privacy from "./Screens/Privacy";
import Terms from "./Screens/Terms";

import "./App.css";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
