import { useState } from "react";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as FooterLogo } from "../../assets/footer_logo.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo_icon.svg";
import { ReactComponent as BudIcon } from "../../assets/between_bud_logo.svg";
import { ReactComponent as DevelopCompanyIcon } from "../../assets/develop_company_logo.svg";
import { ReactComponent as ListTypeIcon } from "../../assets/list_type.svg";
import { ReactComponent as PeopleIcon1 } from "../../assets/people_1.svg";
import { ReactComponent as PeopleIcon2 } from "../../assets/people_2.svg";
import { ReactComponent as BubbleMailerIcon } from "../../assets/bubble_mailer.svg";
import { ReactComponent as MobileMenu } from "../../assets/mobile_menu.svg";
import { ReactComponent as MobileCloseIcon } from "../../assets/close_icon.svg";
import tradeImage from "../../assets/trade.png";
import browseImage from "../../assets/browse.png";
import offerImage from "../../assets/offer.png";
import exchangeImage from "../../assets/exchange.png";
import bubbleMailer from "../../assets/bubble_mailer.png";
import notesImage from "../../assets/notes.png";
import greenBarImage from "../../assets/green_bar.png";
import greenRedBarImage from "../../assets/green_red_bar.png";
import roadmapFirstImage from "../../assets/roadmap_1.png";
import roadmapSecondImage from "../../assets/roadmap_2.png";
import roadmapThirdImage from "../../assets/roadmap_3.png";
import AndroidIcon from "../../components/AndroidIcon";
import IPhoneIcon from "../../components/IPhoneIcon";
import EmailIcon from "../../components/EmailIcon";
import useWindowDimensions from "../../components/useWindowDimensions";
import onLinkClick from "../../helpers/onLinkClick";

import "./NotFound.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { height, width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <>
      <div className={isMenuOpened ? "menu-opened-container" : "container"}>
        <header className="header">
          <div className="menu">
            <Logo onClick={onLinkClick("/", false)} />
            {
              isMobile
                ?
                <div onClick={() => setIsMenuOpened(!isMenuOpened)}>
                  <MobileMenu />
                </div>
                :
                <div className="menu-item-box">
                  <a className="menu-item" href="/">Home</a>
                </div>
            }
          </div>
          {
            isMobile && isMenuOpened &&
            <div className="mobile-menu">
              <div style={{ padding: "3rem" }}>
                <div style={{ textAlign: "end" }} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                  <MobileCloseIcon />
                </div>
                <div className="mobile-menu-item-box">
                  <Link className="mobile-menu-item" href="/" onClick={() => setIsMenuOpened(!isMenuOpened)}>Home</Link>
                  <a className="mobile-menu-item-button" href="/" onClick={() => setIsMenuOpened(!isMenuOpened)}>Get The App</a>
                </div>
                <footer className="mobile-menu-footer">
                  <span className="footer-text">© {new Date().getFullYear()} Between Buds All Rights Reserved.</span>
                </footer>
              </div>
            </div>
          }
          <div className="header-content">
            <div className="header-content-box">
              <div className="header-content-title">
                Page Not Found
              </div>
              <div className="header-content-text">
                  The page you are looking for does not exist :(
              </div>
            </div>
            <div className="header-content-image">
              <LogoIcon />
            </div>
          </div>
        </header>
        <div className={isMenuOpened ? "menu-opened-body" : "body"}>
          <div className="footer-top-split" />
          <div className="footer-container" id="about">
            <div className="footer-logo">
              <FooterLogo />
              <DevelopCompanyIcon style={{ marginLeft: "2rem", cursor: 'pointer' }} onClick={onLinkClick("https://www.razorsharpconsulting.com")} />
            </div>
            <div className="footer-content">
              {
                !isMobile &&
                <>
                  <div className="footer-item">
                    <div className="footer-item-title">Product</div>
                    <a className="footer-item-link" href="#about">About</a>
                    <a className="footer-item-link" href="#works">How It Works</a>
                    <a className="footer-item-link" href="#roadmap">RoadMap</a>
                  </div>
                  <div className="footer-item">
                    <div className="footer-item-title">Privacy & Terms</div>
                    <a className="footer-item-link" href="/privacy">Privacy Policy</a>
                    <a className="footer-item-link" href="/terms-of-use">Terms</a>
                  </div>
                  <div className="footer-item">
                    <div className="footer-item-title">Have a Question?</div>
                    <div className="footer-item-link">We would love to hear your feedback.  This app can only grow by word of mouth, so we want to make sure you are happy!</div>
                  </div>
                  <div className="footer-item">
                    <div className="footer-email-button">
                      <div className="button-email">
                        <EmailIcon fill="#fff" width={20} />
                        <div className="button-text" style={{ color: "#fff" }} onClick={onLinkClick("mailto:info@betweenbuds.ca")}>Email Us</div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {
                isMobile &&
                <>
                  <div className="footer-item">
                    <div className="footer-item-title">Have a Question?</div>
                    <div className="footer-item-link">We would love to hear your feedback.  This app can only grow by word of mouth, so we want to make sure you are happy!</div>
                  </div>
                  <div className="footer-item">
                    <div className="footer-email-button">
                      <div className="button-email">
                        <EmailIcon fill="#fff" width={20} />
                        <div className="button-text" style={{ color: "#fff" }} onClick={onLinkClick("mailto:info@betweenbuds.ca")}>Email Us</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3rem" }}>
                    <div className="footer-item">
                      <div className="footer-item-title">Product</div>
                      <a className="footer-item-link" href="#about">About</a>
                      <a className="footer-item-link" href="#works">How It Works</a>
                      <a className="footer-item-link" href="#roadmap">RoadMap</a>
                    </div>
                    <div className="footer-item">
                      <div className="footer-item-title">Privacy & Terms</div>
                      <a className="footer-item-link">Privacy Policy</a>
                      <a className="footer-item-link">Terms</a>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <footer className="footer">
          <span className="footer-text">©  {new Date().getFullYear()} Between Buds All Rights Reserved.</span>
        </footer>
      </div>
    </>
  );
};

export default NotFound;