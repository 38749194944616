import { withRouter } from "react-router-dom";

export default (url, newWindow = false) => {
    return (e) => {
        e.preventDefault();
        let link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        if (newWindow) {
            link.target = "_blank";
        }
        link.click();
        document.body.removeChild(link);
    };
};
