import { useState } from "react";
import useWindowDimensions from "./useWindowDimensions";


import { ReactComponent as DevelopCompanyIcon } from "../assets/develop_company_logo.svg";
import { ReactComponent as FooterLogo } from "../assets/footer_logo.svg";
import EmailIcon from "./EmailIcon";
import { Link } from "react-router-dom";


const Footer = () => {
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const { height, width } = useWindowDimensions();
    const isMobile = width < 900;

    const onLinkClick = (url, newWindow = true) => (e) => {
        e.preventDefault();
        let link = document.createElement('a');
        link.target = newWindow ? "_blank" : "";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    return (
        <>
            <div className="footer-top-split" />
            <div className="footer-container" id="about">
                <div className="footer-logo">
                    <FooterLogo />
                    <DevelopCompanyIcon style={{ marginLeft: "2rem", cursor: 'pointer' }} onClick={onLinkClick("https://www.razorsharpconsulting.com")} />
                </div>
                <div className="footer-content">
                    {
                        !isMobile &&
                        <>
                            <div className="footer-item">
                                <div className="footer-item-title">Product</div>
                                <a className="footer-item-link" href="/#about">About</a>
                                <a className="footer-item-link" href="/#works">How It Works</a>
                            </div>
                            <div className="footer-item">
                                <div className="footer-item-title">Privacy & Terms</div>
                                <Link className="footer-item-link" to="/privacy">Privacy Policy</Link>
                                <Link className="footer-item-link" to="/terms-of-use">Terms</Link>
                            </div>
                            <div className="footer-item">
                                <div className="footer-item-title">Have a Question?</div>
                                <div className="footer-item-link">We would love to hear your feedback.  This app can only grow by word of mouth, so we want to make sure you are happy!</div>
                            </div>
                            <div className="footer-item">
                                <div className="footer-email-button">
                                    <div className="button-email">
                                        <EmailIcon fill="#fff" width={20} />
                                        <div className="button-text" style={{ color: "#fff" }} onClick={onLinkClick("mailto:info@betweenbuds.ca")}>Email Us</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        isMobile &&
                        <>
                            <div className="footer-item">
                                <div className="footer-item-title">Have a Question?</div>
                                <div className="footer-item-link">We would love to hear your feedback.  This app can only grow by word of mouth, so we want to make sure you are happy!</div>
                            </div>
                            <div className="footer-item">
                                <div className="footer-email-button">
                                    <div className="button-email">
                                        <EmailIcon fill="#fff" width={20} />
                                        <div className="button-text" style={{ color: "#fff" }} onClick={onLinkClick("mailto:info@betweenbuds.ca")}>Email Us</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3rem" }}>
                                <div className="footer-item">
                                    <div className="footer-item-title">Product</div>
                                    <a className="footer-item-link" href="/#about">About</a>
                                    <a className="footer-item-link" href="/#works">How It Works</a>
                                </div>
                                <div className="footer-item">
                                    <div className="footer-item-title">Privacy & Terms</div>
                                    <Link className="footer-item-link" to="/privacy">Privacy Policy</Link>
                                    <Link className="footer-item-link" to="/terms-of-use">Terms</Link>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <footer className="footer">
                <span className="footer-text">©  {new Date().getFullYear()} Between Buds All Rights Reserved.</span>
            </footer>
        </>
    )
}

export default Footer;