import { useState } from "react";
import { ReactComponent as BudIcon } from "../../assets/between_bud_logo.svg";
import browseImage from "../../assets/browse.png";
import bubbleMailer from "../../assets/bubble_mailer.png";
import { ReactComponent as MobileCloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as DevelopCompanyIcon } from "../../assets/develop_company_logo.svg";
import exchangeImage from "../../assets/exchange.png";
import greenBarImage from "../../assets/green_bar.png";
import greenRedBarImage from "../../assets/green_red_bar.png";
import { ReactComponent as ListTypeIcon } from "../../assets/list_type.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo_icon.svg";
import { ReactComponent as MobileMenu } from "../../assets/mobile_menu.svg";
import notesImage from "../../assets/notes.png";
import offerImage from "../../assets/offer.png";
import { ReactComponent as PeopleIcon1 } from "../../assets/people_1.svg";
import { ReactComponent as PeopleIcon2 } from "../../assets/people_2.svg";
import roadmapFirstImage from "../../assets/roadmap_1.png";
import roadmapSecondImage from "../../assets/roadmap_2.png";
import roadmapThirdImage from "../../assets/roadmap_3.png";
import tradeImage from "../../assets/trade.png";
import AndroidIcon from "../../components/AndroidIcon";
import Footer from "../../components/Footer";
import IPhoneIcon from "../../components/IPhoneIcon";
import useWindowDimensions from "../../components/useWindowDimensions";
import onLinkClick from "../../helpers/onLinkClick";
import "./Home.css";



const Home = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { height, width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <>
      <div className={isMenuOpened ? "menu-opened-container" : "container"}>
        <header className="header">
          <div className="menu">
            <Logo />
            {
              isMobile
                ?
                <div onClick={() => setIsMenuOpened(!isMenuOpened)}>
                  <MobileMenu />
                </div>
                :
                <div className="menu-item-box">
                  <a className="menu-item" href="#about">About</a>
                  <a className="menu-item" href="#works">How It Works</a>
                  <a className="menu-item-button" href="#getApp">Get The App</a>
                </div>
            }
          </div>
          {
            isMobile && isMenuOpened &&
            <div className="mobile-menu">
              <div style={{ padding: "3rem" }}>
                <div style={{ textAlign: "end" }} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                  <MobileCloseIcon />
                </div>
                <div className="mobile-menu-item-box">
                  <a className="mobile-menu-item" href="#about" onClick={() => setIsMenuOpened(!isMenuOpened)}>About</a>
                  <a className="mobile-menu-item" href="#works" onClick={() => setIsMenuOpened(!isMenuOpened)}>How It Works</a>
                  <a className="mobile-menu-item" href="#roadmap" onClick={() => setIsMenuOpened(!isMenuOpened)}>Road Map</a>
                  <a className="mobile-menu-item-button" href="#getApp" onClick={() => setIsMenuOpened(!isMenuOpened)}>Get The App</a>
                </div>
                <footer className="mobile-menu-footer">
                  <span className="footer-text">© {new Date().getFullYear()} Between Buds All Rights Reserved.</span>
                </footer>
              </div>
            </div>
          }
          <div id="about" className="header-content">
            <div className="header-content-box">
              <div className="header-content-title">
                Trade cannabis seeds, flower, concentrates, and edibles with other growers and consumers around Canada.
              </div>
              <div className="header-content-text">
                We do not store your contact information after the exchange has been completed.
              </div>
            </div>
            <div className="header-content-image">
              <LogoIcon />
            </div>
          </div>
        </header>
        <div className={isMenuOpened ? "menu-opened-body" : "body"}>
          <div className="works-container" id="works">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="works-logo">
                <BudIcon />
                <DevelopCompanyIcon className="developed-company-icon" onClick={onLinkClick("https://www.razorsharpconsulting.com")} style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="works-box">
              <div className="works-content">
                <div className="works-content-title">
                  How does it work?
                </div>
                <div className="works-content-text">
                  Browse the existing listings, or create a new one for something that interests you.
                  <b> Submit an offer to the listing for something that you have to trade. </b>
                  If the other user likes your offer, they will accept it and an exchange is created.
                  You both will enter your shipping information, then pay for shipping, and we will email you a shipping label for your package.
                  After your packages arrive we will ask for feedback on the exchange.
                  <br /><br /><b>Between Buds seeks to become the largest legal cannabis trade platform in Canada.</b>
                  <br /><br />We are not able to promote the app on any social media or app store, so please try to share the app as much as you can.
                </div>
              </div>
              <img src={tradeImage} className="trade-image" alt="Trade Image" />
            </div>
            {
              isMobile
                ?
                <>
                  <img src={browseImage} className="browse-image" alt="Browse Image" />
                  <img src={exchangeImage} className="exchange-image" alt="Exchange Image" />
                  <img src={offerImage} className="offer-image" alt="Offer Image" />
                </>
                :
                <>
                  <div className="row">
                    <img src={browseImage} className="browse-image" alt="Browse Image" />
                    <img src={offerImage} className="offer-image" alt="Offer Image" />
                  </div>
                  <div className="row">
                    <img src={exchangeImage} className="exchange-image" alt="Exchange Image" />
                  </div>
                </>
            }
          </div>
          <div className="get-app-container" id="getApp">
            <div className="get-app-content">
              <div className="get-app-content-title">Where Do I Get The App?</div>
              <div className="get-app-content-text">
                Between Buds is the premier platform for trading cannabis seeds, flower, concentrates, and edibles with other growers and consumers across Canada. Our user-friendly interface allows you to browse existing listings or create a new one for something that interests you. Submit an offer, and if the other user likes it, they'll accept, and an exchange is created. Both parties enter their shipping information and pay for shipping, and we'll email you a shipping label for your package.
              </div>
              <div className="get-app-content-text" style={{ fontWeight: "600", marginTop: "25px" }}>
                Click the link below for either Android or iOS to download the app and get started.
              </div>
              <div className="button-box">
                <div className="button-android" onClick={onLinkClick("https://play.google.com/store/apps/details?id=com.razorsharpconsulting.betweenbuds")}>
                  <AndroidIcon fill="#000" width={20} />
                  <div className="button-text">Android</div>
                </div>
                <div className="button-ios" onClick={onLinkClick("#")}>
                  <IPhoneIcon fill="#000" width={20} />
                  <div className="button-text">iPhone (coming soon)</div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <img src={notesImage} className="note-image" alt="Notes Image" />
              <div className="get-app-content" style={{ textAlign: "left" }}>
                <div className="get-app-content-title">Notes</div>
                <div className="get-app-content-text">
                  iOS is coming soon. - Jan 31, 2022
                  <br /><br />
                  The app is under active development and will change often. Please report any issues through the feed back feature in your profile.
                  <br /><br />
                  <b>All data within the app will be deleted on Jan 31, 2022.</b>
                  <br /><br />
                  Payments are currently in "test mode". <b>Use Credit Card 4242 4242 4242 4242 4/24 424.</b>
                </div>
                <div className="button-email" style={{ margin: "0px", marginTop: "2rem" }}>
                  <div className="button-text" style={{ marginLeft: "0px" }} onClick={onLinkClick("mailto:info@betweenbuds.ca")}>Email US</div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="roadmap-container" id="roadmap">
            <div className="roadmap-title">2022 Roadmap</div>
            <div className="roadmap-content">
              <img src={greenBarImage} className="green-bar" alt="Green Bar" />
              <div className="row">
                <div className="roadmap-left"></div>
                <div className="roadmap-center">
                  <div className="roadmap-label">Q1</div>
                </div>
                <div className="roadmap-right">
                  <div className="roadmap-item">
                    <img src={roadmapFirstImage} className="roadmap-item-image" alt="Roadmap Image" />
                    <div className="roadmap-item-title">March 31</div>
                    <ul className="roadmap-item-text">
                      <li>Beta Testing (Android/iOS)</li>
                      <li>Website Development</li>
                      <li>Final App Improvements</li>
                      <li>Enhance strain/review/feedback meta data</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="roadmap-left">
                  {
                    !isMobile &&
                    <div className="roadmap-item">
                      <img src={roadmapSecondImage} className="roadmap-item-image" alt="Roadmap Image" />
                      <div className="roadmap-item-title">June 30</div>
                      <ul className="roadmap-item-text">
                        <li>App Released</li>
                        <li>Smart Contract Development (Between Buds: Bud)</li>
                        <li>Add advertising to the app (online dispo, etc.)</li>
                        <li>Find artist to develop NFT artwork</li>
                      </ul>
                    </div>
                  }
                </div>
                <div className="roadmap-center">
                  <div className="roadmap-label">Q2</div>
                </div>
                <div className="roadmap-right">
                  {
                    isMobile &&
                    <div className="roadmap-item">
                      <img src={roadmapSecondImage} className="roadmap-item-image" alt="Roadmap Image" />
                      <div className="roadmap-item-title">June 30</div>
                      <ul className="roadmap-item-text">
                        <li>App Released</li>
                        <li>Smart Contract Development (Between Buds: Bud)</li>
                        <li>Add advertising to the app (online dispo, etc.)</li>
                        <li>Find artist to develop NFT artwork</li>
                      </ul>
                    </div>
                  }
                </div>
              </div>
              <div className="row">
                <div className="roadmap-left"></div>
                <div className="roadmap-center">
                </div>
                <div className="roadmap-right">
                  <div className="roadmap-item">
                    <img src={roadmapThirdImage} className="roadmap-item-image" alt="Roadmap Image" />
                    <div className="roadmap-item-title">September 30</div>
                    <ul className="roadmap-item-text">
                      <li>Mint NFT to users every 25 trades</li>
                      <li>Enable secondary marketplace sales</li>
                      <li>Community/Explore</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="roadmap-bottom-split" />
          <div className="workflow-container">
            <div className="workflow-title">High-Level Workflow</div>
            <div className="workflow-content">
              <img src={greenRedBarImage} className="green-bar" alt="Green Bar" />
              <div className="row">
                <div className="workflow-left"></div>
                <div className="workflow-center"></div>
                <div className="workflow-right">
                  <div className="workflow-item">
                    <ListTypeIcon className="list-type-icon" />
                    Listing Created
                  </div>
                  <div className="workflow-item">Offers Created</div>
                  <div className="workflow-item">Exchange Created</div>
                </div>
              </div>
              <div className="row">
                <div className="workflow-left">
                  {
                    !isMobile &&
                    <>
                      <div className="workflow-item-1">Users Add Address/Package Info</div>
                      <div className="workflow-item">Wait Until Both Complete</div>
                    </>
                  }
                </div>
                <div className="workflow-center"></div>
                <div className="workflow-right">
                  {
                    isMobile &&
                    <>
                      <div className="workflow-item-1">Users Add Address/Package Info</div>
                      <div className="workflow-item">Wait Until Both Complete</div>
                    </>
                  }
                </div>
              </div>
              <div className="row">
                <div className="workflow-left"></div>
                <div className="workflow-center"></div>
                <div className="workflow-right">
                  <div className="workflow-item-1">
                    <PeopleIcon1 className="people-icon-1" />
                    Users Pay Shipping
                  </div>
                  <div className="workflow-item">
                    <PeopleIcon2 className="people-icon-2" />
                    Wait Until Both Pay
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="workflow-left">
                  {
                    !isMobile &&
                    <>
                      <div className="workflow-item" style={{ padding: 0 }}>
                        <img src={bubbleMailer} className="bubble-mailer-icon" alt="Bubble Mailer" />
                        {/* <BubbleMailerIcon className=""/> */}
                      </div>
                      <div className="workflow-item">
                        Shipping Labels Created/Emailed
                      </div>
                      <div className="workflow-item">Monitor Shipping Until Recieved</div>
                      <div className="workflow-item">Users Add Feedback</div>
                    </>
                  }
                </div>
                <div className="workflow-center"></div>
                <div className="workflow-right">
                  {
                    isMobile &&
                    <>
                      <div className="workflow-item" style={{ padding: 0 }}>
                        <img src={bubbleMailer} className="bubble-mailer-icon" alt="Bubble Mailer" />
                      </div>
                      <div className="workflow-item">
                        Shipping Labels Created/Emailed
                      </div>
                      <div className="workflow-item">Monitor Shipping Until Recieved</div>
                      <div className="workflow-item">Users Add Feedback</div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="workflow-title">Exchange Completed</div>
            <div className="download-app-box">
              <div className="download-app-content">
                <div className="download-app-title">
                  Download The App
                </div>
                <div className="download-app-text">
                  Between Buds creates a safe place where you can trade cannabis with other growers in our community.  The app ensures your privacy while allowing you to obtain different genetics from other growers and enthusiasts.
                </div>
              </div>
              <div className="download-button-group">
                <div className="button-android" onClick={onLinkClick("https://play.google.com/store/apps/details?id=com.razorsharpconsulting.betweenbuds")}>
                  <AndroidIcon fill="#fff" width={20} />
                  <div className="button-text" style={{ color: "#fff" }}>Android</div>
                </div>
                <div className="button-ios" onClick={onLinkClick("#")} style={{ borderColor: "#fff" }}>
                  <IPhoneIcon fill="#fff" width={20} />
                  <div className="button-text" style={{ color: "#fff" }}>iPhone (coming soon)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;