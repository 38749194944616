import { useState } from "react";
import { ReactComponent as MobileCloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo_icon.svg";
import { ReactComponent as MobileMenu } from "../../assets/mobile_menu.svg";
import Footer from "../../components/Footer";
import useWindowDimensions from "../../components/useWindowDimensions";
import onLinkClick from "../../helpers/onLinkClick";
import "./index.css";



const NotFound = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { height, width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <>
      <div className={isMenuOpened ? "menu-opened-container" : "container"}>
        <header className="header">
          <div className="menu">
            <Logo onClick={onLinkClick("/", false)} />
            {
              isMobile
                ?
                <div onClick={() => setIsMenuOpened(!isMenuOpened)}>
                  <MobileMenu />
                </div>
                :
                <div className="menu-item-box">
                  <a className="menu-item" href="/">Home</a>
                </div>
            }
          </div>
          {
            isMobile && isMenuOpened &&
            <div className="mobile-menu">
              <div style={{ padding: "3rem" }}>
                <div style={{ textAlign: "end" }} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                  <MobileCloseIcon />
                </div>
                <div className="mobile-menu-item-box">
                  <a className="mobile-menu-item" href="/" onClick={() => setIsMenuOpened(!isMenuOpened)}>Home</a>
                  <a className="mobile-menu-item-button" href="/" onClick={() => setIsMenuOpened(!isMenuOpened)}>Get The App</a>
                </div>
                <footer className="mobile-menu-footer">
                  <span className="footer-text">© {new Date().getFullYear()} Between Buds All Rights Reserved.</span>
                </footer>
              </div>
            </div>
          }
          <div className="header-content">
            <div className="header-content-box">
              <div className="header-content-title">
                Privacy Policy
              </div>
              <div className="header-content-text">
                Updated January 17, 2022
              </div>
            </div>
            <div className="header-content-image">
              <LogoIcon />
            </div>
          </div>
        </header>
        <div className={isMenuOpened ? "menu-opened-body" : "body"}>
          <div className="works-container" id="works">
            <div className="works-content-title">
              Privacy Policy
            </div>
            <div className="works-content-text">
              <p>Anonymity is a priority for Between Buds and our users.  This privacy Policy document contains types of information that is collected and recorded by Between Buds and how we use it.</p>
              <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at <a href="mailto:info@betweenbuds.ca">info@betweenbuds.ca</a>.</p>
              <p>This privacy policy applies to both our mobile application and our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect and the Between Buds app. This policy is not applicable to any information collected offline or via channels other than the before mentioned.</p>
              <h3>Consent</h3>
              <p>By using our website or mobile app, you hereby consent to our Privacy Policy and agree to its terms.</p>
              <p><strong>Information we collect</strong></p>
              <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
              <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
              <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
              <p><strong>How we use your information</strong></p>
              <p>We use the information we collect in various ways, including to:</p>
              <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
                <li>Log Files</li>
              </ul>
              <p>Between Buds follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
              <p><strong>Cookies and Web Beacons</strong></p>
              <p>Like any other website, Between Buds uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
              <p><strong>DoubleClick DART Cookie</strong></p>
              <p>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL &ndash; <a href="https://policies.google.com/technologies/ads"><span class="s1">https://policies.google.com/technologies/ads</span></a></p>
              <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
              <ul>
                <li>Google: <a href="https://policies.google.com/technologies/ads"><span class="s1">https://policies.google.com/technologies/ads</span></a></li>
              </ul>
              <p><strong>Advertising Partners Privacy Policies</strong></p>
              <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Between Buds.</p>
              <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Between Buds, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
              <p>Note that Between Buds has no access to or control over these cookies that are used by third-party advertisers.</p>
              <p><strong>Third-Party Privacy Policies</strong></p>
              <p>Between Buds Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</p>
              <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. <a href="https://www.privacypolicygenerator.info/privacy-policy-cookies-clauses/"><span class="s1">What Are Cookies?</span></a></p>
              <p><strong>Children's Information</strong></p>
              <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
              <p>Between Buds does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
              <p><strong>Questions</strong></p>
              <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@betweenbuds.ca">info@betweenbuds.ca</a>.</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NotFound;